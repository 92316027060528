// возвращает абсолютные урлы на сайт для SSR, которые нужны в верстке (например канониклы и прочее)
function url(path: string): string {
  const baseUrl = process.env.PUBLIC_ORIGIN || 'https://pogovorim.online'

  return `${baseUrl}${path}`
}

export const URL = {
  baseUrl: url(''),
  index: url('/'),
};

// возвращает абослуютный url с path для запросов в API для SSR
function apiUrl(path: string): string {
  const baseUrl = process.env.POGONLINE_BACKEND_URL || 'http://localhost'

  return `${baseUrl}/api/v1${path}`
}

// урлы для запросов с сервера в API (только для SSR)
export const URL_API = {
  getTherapistsWithParams: apiUrl('/therapists/search/with_filter?limit=10&page=1'),
  getTherapyMethods: apiUrl('/common/therapy_methods'),
  getGroupDiseases: apiUrl('/common/diseases_groups'),
  getTherapistsWithFilter: apiUrl('/therapists/search/with_filter'),
  getTherapistUrlOfId: (therapistId: string ) => apiUrl(`/therapists/therapist-url-from-id/${therapistId}`),
  getTherapist: (url: string) => apiUrl(`/therapists/${url}`),
  getMe: apiUrl('/users/me'),
};

export const URL_PUBLIC = {
  allTherapists: '/all-therapists',
  login: '/login',
  loginBusiness: '/login/business',
  clientSignUp: '/sign-up/client',
  therapistSignUp: '/sign-up/therapist',
  privacyPolicy: '/documents/privacy-policy.pdf',
  userTermsOfUse: '/documents/public-offer-user.pdf',
  expertTermsOfUse: '/documents/public-offer-expert.pdf',
  forClients: '/',
  forTherapists: '/therapists',
  forBusiness: '/business',
  therapistsDetailPage: (id: string) => `/therapist/${id}`,
};

// возвращает относительный урл для запросов с фронта в API
// для запуска приложения локально возвращает абсолютный url c хостом из NEXT_PUBLIC_POGONLINE_BACKEND_URL
function publicApiUrl(path: string): string {
  let devHostname = ''
  if (process.env.NODE_ENV === 'development') {
    devHostname = process.env.NEXT_PUBLIC_POGONLINE_BACKEND_URL || ''
  }

  return `${devHostname}/api/v1${path}`
}

export const URL_API_PUBLIC = {
  baseUrl: publicApiUrl(''),

  // store/api/allTherapists
  getTherapistsWithFilter: '/therapists/search/with_filter',
  getTherapistsRecommended: '/therapists/search/recommended',

  // store/api/auth
  signIn: '/auth/sign-in',
  signUp: '/auth/sign-up',
  phoneSendVerification: '/auth/phone_number/send_verification',
  phoneCheckVerification: '/auth/phone_number/check_verification',
  emailSendConfirmation: '/auth/confirm_email/send',
  emailConfirmConfirmation: '/auth/confirm_email/confirm',
  sendSmsToChangeForgottenPassword: '/auth/forget_password/phone/send',
  sendUrlToChangeForgottenPassword: '/auth/forget_password/email/send',
  changeForgottenPasswordEmail: '/auth/forget_password/email',
  changeForgottenPasswordPhone: '/auth/forget_password/phone',
  changeEmail: '/auth/email',
  changePhone: '/auth/phone',
  changePassword: '/auth/password',
  checkEmail: '/auth/check/email',
  checkPhone: '/auth/check/phone',
  checkPassword: '/auth/check/password',
  sendConfirmation: '/auth/email/send',
  signUpPhone: '/auth/sign-up/phone',
  signUpEmail: '/auth/sign-up/email',
  signInPhone: '/auth/sign-in/phone',
  signInPhoneSendVerification: '/auth/sign-in/phone/send_verification',
  updateEmailWithoutConfirmation: '/auth/email/with_no_confirmation',

  // store/api/bankCard
  getBankCardData: '/payment-gateway/payment-method',
  getCheckoutUrl: '/payment-gateway/payment-method/attach',
  deleteBankCard: '/payment-gateway/payment-method/detach',

  // store/api/clientOnboarding
  postClientOnboarding: '/patients/onboarding',
  postClientRecommendationFilter: '/patients/recommendation-filter',

  // store/api/clientProfile
  deleteProfile: '/users',
  deleteClientAvatar: '/patients/avatar',
  updateAvatar: '/patients/avatar',
  updatePatientInformation: '/patients',
  getPatientProfile: '/patients/profile',

  // store/api/clientTherapists
  getMyTherapists: '/patients/therapist',
  deleteSessionsWithTherapist: (therapistId: string) => `/sessions/therapist/${therapistId}`,

  // store/api/common
  getGroupDiseases: '/common/diseases_groups',
  getDiseases: '/common/diseases',
  getTherapyMethods: '/common/therapy_methods',
  getFaq: '/common/faq',

  // store/api/company
  getCompanyStatistic: 'companies/statistics',
  getPromoCodes: 'companies/promocodes',
  requestPromoCodes: 'companies/promocodes-request',
  sendPromoCodes: 'companies/promocodes/send',
  getCompanyStatisticsXls: '/companies/statistics/xls',

  // store/api/medsiAuthorization
  medsiValidateToken: '/medsi/auth/validate-token',
  medsiSignUp: '/medsi/auth/sign-up',
  medsiSignIn: '/medsi/auth/sign-in',
  medsiRecover: '/medsi/auth/recover',

  // store/api/notifications
  getNotifications: '/notifications',
  markAllNotificationsViewed: '/notifications',
  clearNotifications: '/notifications',

  // // store/api/patientNotes
  addPatientNote: (patientId: string) => `/therapists/patient/${patientId}/notes`,
  editPatientNote: (patientId: string, noteId: string) => `/therapists/patient/${patientId}/notes/${noteId}`,
  deletePatientNote: (patientId: string, noteId: string) => `/therapists/patient/${patientId}/notes/${noteId}`,

  // store/api/payments
  getPayments: '/payments',
  getPaymentStatistic: '/payments/statistics',

  // store/api/promocodes
  checkPromocode: '/promocodes/check',
  checkPromocodeByTherapist: '/promocodes/check-by-therapist',

  // store/api/schedule
  getSchedule: '/therapists/schedule',
  createAvailabilities: '/therapists/availability',
  updateAvailabilities: (availabilityId: string) => `/therapists/availability/${availabilityId}`,
  deleteAvailabilities: (availabilityId: string) => `/therapists/availability/${availabilityId}`,

  // store/api/session-payment
  getUpcomingSessionPaymentInfo: (availabilityId: string) =>
    `/session-payment/upcoming/${availabilityId}`,
  bookSession: '/session-payment/',
  bookSessionV2: '/session-booking/',
  bookSessionByTherapist: '/session-payment/book_by_therapist',

  // store/api/sessions
  getClientSessions: '/patients/sessions',
  getClientHistorySessions: '/patients/sessions/history',
  getClientTotalSessions: '/patients/sessions/total',
  getClientActiveSessions: '/patients/sessions/active',
  getTherapistSessions: '/therapists/sessions',
  getTherapistActiveSessions: '/therapists/sessions/active',
  getTherapistHistorySessions: '/therapists/sessions/history',
  getTherapistFreeAvailabilities: (therapistId: string) =>
  `/therapists/${therapistId}/availability`,
  getSessionNotes: (sessionId: string) => `/sessions/${sessionId}/notes`,
  addSessionNote: (sessionId: string) => `/sessions/${sessionId}/notes`,
  addSessionOutcome: (sessionId: string) => `sessions/${sessionId}/outcome`,
  cancelSession: (sessionId: string) => `/session-payment/${sessionId}/cancel`,
  cancelSessionV2: '/session-booking/cancel',
  timeshiftSession: '/session-booking/reschedule',
  sendRequestSession: (therapistId: string) =>
    `/therapists/${therapistId}/request_session`,

  // store/api/therapistClients
  getClients: '/therapists/patients',
  getClientById: (patientId: string) => `/patients/${patientId}`,
  archivePatient: (patientId: string) => `/therapists/patients/${patientId}/archive`,
  rejectPatient: (patientId: string) => `/therapists/patients/${patientId}`,
  unarchivePatient: (patientId: string) => `/therapists/patients/${patientId}/unarchive`,

  // store/api/therapistProfile
  getTherapistProfile: '/therapists/profile',
  getTherapistProfileUpdate: '/therapist-update',
  deleteTherapistProfileUpdate: (updateId: string) =>
  `/therapist-update/${updateId}`,
  postTherapistProfile: '/therapist-update/',
  postCreateProfile: '/therapists',
  deleteVideo: '/therapists/video',

  // store/api/users
  getMe: '/users/me',
  updateNotificationTime: '/users/notification',

  // store/api/videoCall
  joinSession: (sessionId: string) => `/sessions/${sessionId}/join`,
  getTestSessionToken: '/sessions/get-test-session-token',

  // ...
  requestCompany: '/companies',
  getPublicAssignedUrl: '/get-public-signed-url',
  webhookAmoMirUserSentForm: '/webhook-amo/mir-user-sent-form',
  pixelWrite: '/logger/pixel',
};

// урлы на страницы блога
function blogUrl(path: string): string {
  const baseUrl = process.env.POGONLINE_BLOG_URL || 'http://localhost'

  return `${baseUrl}${path}`
}

// урлы для запросов с сервера в API блока
function blogApiUrl(path: string): string {
  const baseUrl = process.env.POGONLINE_BLOG_URL || 'http://localhost'

  return `${baseUrl}/api${path}`
}

export const URL_BLOG = {
  baseUrl: blogUrl(''),
  articles: blogApiUrl('/articles'),
  article: (url: string | string[]) => blogApiUrl(`/articles/${url}`),
  tags: blogApiUrl('/tags'),
  comments: blogApiUrl('/comments'),
};

function publicUrlAbsolute(path: string): string {
  let origin = 'https://pogovorim.online';

  if (typeof window != 'undefined') {
    origin = window.location.origin;
  }

  return `${origin}${path}`
}

export const URL_PUBLIC_FOR_RETURNS = {
  baseUrl: publicUrlAbsolute('')
}

import { URL_API_PUBLIC } from '@/shared/urls';
import { HttpMethods } from '@app/types';
import {
  CheckAuthTermResponseDto,
  CheckEmailRequestDto,
  CheckPasswordRequestDto,
  CheckPhoneNumberVerificationDto,
  CheckPhoneRequestDto,
  CheckVerificationCodeDto,
  RestorePasswordByEmailRequestDto,
  RestorePasswordByPhoneRequestDto,
  SendingStatusDto,
  SendPhoneNumberVerificationDto,
  SendUpdateEmailConfirmationRequestDto,
  UpdateEmailWithoutConfirmationRequestDto,
  UpdatePasswordRequestDto,
  UpdatePhoneRequestDto,
  UserEmailDto,
  UserEmailSignUpRequestDto,
  UserPhoneSignInRequestDto,
  UserPhoneSignUpRequestDto,
  UserSignInDto,
  UserSignUpDto,
  UserWithTokenAndMetadataResponseDto,
  UserWithTokenResponseDto,
} from '@app/api';
import { actions } from '@app/store';
import { baseApi } from '../baseApi';

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    signIn: builder.mutation<
      UserWithTokenAndMetadataResponseDto,
      UserSignInDto
    >({
      query: (body) => ({
        url: URL_API_PUBLIC.signIn,
        method: HttpMethods.post,
        body,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(({ data }) => {
          dispatch(actions.auth.setUserWithTokenAndMetadata(data));
        });
      },
    }),
    signUp: builder.mutation<
      UserWithTokenAndMetadataResponseDto,
      UserSignUpDto
    >({
      query: (body) => ({
        url: URL_API_PUBLIC.signUp,
        method: HttpMethods.post,
        body,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(({ data }) => {
          dispatch(actions.auth.setUserWithTokenAndMetadata(data));
        });
      },
    }),
    sendUrlToChangeForgottenPassword: builder.mutation<
      SendingStatusDto,
      UserEmailDto
    >({
      query: (body) => ({
        url: URL_API_PUBLIC.sendUrlToChangeForgottenPassword,
        method: HttpMethods.post,
        body,
      }),
    }),
    sendSmsToChangeForgottenPassword: builder.mutation<
      SendingStatusDto,
      SendPhoneNumberVerificationDto
    >({
      query: (body) => ({
        url: URL_API_PUBLIC.sendSmsToChangeForgottenPassword,
        method: HttpMethods.post,
        body,
      }),
    }),
    sendPhoneVerification: builder.mutation<
      SendingStatusDto,
      SendPhoneNumberVerificationDto
    >({
      query: (body) => ({
        url: URL_API_PUBLIC.phoneSendVerification,
        method: HttpMethods.post,
        body,
      }),
    }),
    checkPhoneVerification: builder.mutation<
      CheckVerificationCodeDto,
      CheckPhoneNumberVerificationDto
    >({
      query: (body) => ({
        url: URL_API_PUBLIC.phoneCheckVerification,
        method: HttpMethods.post,
        body,
      }),
    }),
    changeForgottenPasswordPhone: builder.mutation<
      UserWithTokenResponseDto,
      { body: RestorePasswordByPhoneRequestDto }
    >({
      query: ({ body }) => ({
        url: URL_API_PUBLIC.changeForgottenPasswordPhone,
        method: HttpMethods.post,
        body,
      }),
    }),
    changeForgottenPasswordEmail: builder.mutation<
      UserWithTokenResponseDto,
      { body: RestorePasswordByEmailRequestDto; token: string }
    >({
      query: ({ body, token }) => ({
        url: URL_API_PUBLIC.changeForgottenPasswordEmail,
        method: HttpMethods.post,
        body,
        headers: {
          authorization: `Bearer ${token}`,
        },
      }),
    }),

    confirmEmail: builder.query<UserWithTokenAndMetadataResponseDto, string>({
      query: (confirmationToken) => ({
        url: URL_API_PUBLIC.emailConfirmConfirmation,
        method: HttpMethods.post,
        headers: {
          authorization: `Bearer ${confirmationToken}`,
        },
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((res) =>
          dispatch(actions.auth.setUserWithTokenAndMetadata(res.data)),
        );
      },
    }),

    sendConfirmEmail: builder.mutation<SendingStatusDto, UserEmailDto>({
      query: (param) => ({
        url: URL_API_PUBLIC.emailSendConfirmation,
        method: HttpMethods.post,
        body: param,
      }),
    }),

    checkPhone: builder.mutation<
      CheckAuthTermResponseDto,
      CheckPhoneRequestDto
    >({
      query: (body) => ({
        url: URL_API_PUBLIC.checkPhone,
        method: HttpMethods.post,
        body,
      }),
    }),

    checkEmail: builder.mutation<
      CheckAuthTermResponseDto,
      CheckEmailRequestDto
    >({
      query: (body) => ({
        url: URL_API_PUBLIC.checkEmail,
        method: HttpMethods.post,
        body,
      }),
    }),

    changeEmail: builder.query<UserWithTokenAndMetadataResponseDto, string>({
      query: (confirmationToken) => ({
        url: URL_API_PUBLIC.changeEmail,
        method: HttpMethods.patch,
        headers: {
          authorization: `Bearer ${confirmationToken}`,
        },
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((res) =>
          dispatch(actions.auth.setUserWithTokenAndMetadata(res.data)),
        );
      },
    }),

    changePhone: builder.mutation({
      query: (body: UpdatePhoneRequestDto) => ({
        url: URL_API_PUBLIC.changePhone,
        method: HttpMethods.patch,
        body,
      }),
      invalidatesTags: ['userData'],
    }),
    sendConfirmation: builder.mutation<
      SendingStatusDto,
      SendUpdateEmailConfirmationRequestDto
    >({
      query: (body) => ({
        url: URL_API_PUBLIC.sendConfirmation,
        method: HttpMethods.patch,
        body,
      }),
    }),

    changePassword: builder.mutation({
      query: (body: UpdatePasswordRequestDto) => ({
        url: URL_API_PUBLIC.changePassword,
        method: HttpMethods.patch,
        body,
      }),
    }),
    checkPassword: builder.mutation({
      query: (body: CheckPasswordRequestDto) => ({
        url: URL_API_PUBLIC.checkPassword,
        method: HttpMethods.post,
        body,
      }),
    }),
    signUpPhone: builder.mutation<
      UserWithTokenAndMetadataResponseDto,
      UserPhoneSignUpRequestDto
    >({
      query: (body) => ({
        url: URL_API_PUBLIC.signUpPhone,
        method: HttpMethods.post,
        body,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(({ data }) => {
          dispatch(actions.auth.setUserWithTokenAndMetadata(data));
        });
      },
    }),
    signUpEmail: builder.mutation<
      UserWithTokenAndMetadataResponseDto,
      UserEmailSignUpRequestDto
    >({
      query: (body) => ({
        url: URL_API_PUBLIC.signUpEmail,
        method: HttpMethods.post,
        body,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(({ data }) => {
          dispatch(actions.auth.setUserWithTokenAndMetadata(data));
        });
      },
    }),
    signInPhone: builder.mutation<
      UserWithTokenAndMetadataResponseDto,
      UserPhoneSignInRequestDto
    >({
      query: (body) => ({
        url: URL_API_PUBLIC.signInPhone,
        method: HttpMethods.post,
        body,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(({ data }) => {
          dispatch(actions.auth.setUserWithTokenAndMetadata(data));
        });
      },
    }),

    signInPhoneSendVerification: builder.mutation<
      SendingStatusDto,
      SendPhoneNumberVerificationDto
    >({
      query: (body) => ({
        url: URL_API_PUBLIC.signInPhoneSendVerification,
        method: HttpMethods.post,
        body,
      }),
    }),

    updateEmailWithoutConfirmation: builder.mutation<
      UserWithTokenAndMetadataResponseDto,
      UpdateEmailWithoutConfirmationRequestDto
    >({
      query: (body) => ({
        url: URL_API_PUBLIC.updateEmailWithoutConfirmation,
        method: HttpMethods.patch,
        body,
      }),
    }),
  }),
});

export const {
  useSignInMutation,
  useSignUpMutation,
  useSendUrlToChangeForgottenPasswordMutation,
  useSendPhoneVerificationMutation,
  useCheckPhoneVerificationMutation,
  useChangeForgottenPasswordEmailMutation,
  useChangeForgottenPasswordPhoneMutation,
  useSendConfirmEmailMutation,
  useConfirmEmailQuery,
  useCheckPhoneMutation,
  useCheckEmailMutation,
  useChangeEmailQuery,
  useChangePhoneMutation,
  useSendConfirmationMutation,
  useChangePasswordMutation,
  useCheckPasswordMutation,
  useSendSmsToChangeForgottenPasswordMutation,
  useSignUpEmailMutation,
  useSignUpPhoneMutation,
  useSignInPhoneMutation,
  useSignInPhoneSendVerificationMutation,
  useUpdateEmailWithoutConfirmationMutation,
} = authApi;

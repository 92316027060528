import { URL_API_PUBLIC } from '@/shared/urls';
import { HttpMethods, PaginationParams } from '@app/types';
import {
  MessageSuccessResponseDto,
  PatientTherapistsShortInfosResponseDto,
} from '@app/api';
import { baseApi } from '../baseApi';

export const clientTherapistsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMyTherapists: builder.query<
      PatientTherapistsShortInfosResponseDto,
      PaginationParams
    >({
      query: ({ limit = 10, ...rest }) => ({
        url: URL_API_PUBLIC.getMyTherapists,
        method: HttpMethods.get,
        params: { limit, ...rest },
      }),
      providesTags: ['clientTherapists'],
    }),

    deleteSessionsWithTherapist: builder.mutation<
      MessageSuccessResponseDto,
      string
    >({
      query: (therapistId) => ({
        url: URL_API_PUBLIC.deleteSessionsWithTherapist(therapistId),
        method: HttpMethods.delete,
      }),
      invalidatesTags: ['clientTherapists'],
    }),
  }),
});

export const {
  useGetMyTherapistsQuery,
  useDeleteSessionsWithTherapistMutation,
} = clientTherapistsApi;

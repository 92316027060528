import { URL } from '@/shared/urls';
import { DefaultSeoProps } from 'next-seo';

export const defaultSeoProps: DefaultSeoProps = {
  canonical: URL.index,
  description: 'Профессиональная психологическая помощь',
  openGraph: {
    type: 'website',
    locale: 'ru_RU',
    url: URL.index,
    site_name: 'Поговорим Онлайн',
    images: [
      {
        url: `${URL.baseUrl}/logo.jpg`,
        alt: 'Логотип Поговорим Онлайн',
        type: 'image/jpeg',
      },
    ],
    description: 'Профессиональная психологическая помощь',
  },
};

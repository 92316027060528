/* eslint-disable @typescript-eslint/ban-types */
import queryString from 'query-string';
import { UserMetadataResponseDto } from '../api';

export const PATHS = [
  '/alpha-test',
  '/app',
  '/ui',
  '/login',
  '/login/business',
  '/logout',

  '/sign-up/client',
  '/sign-up/client?:query',

  '/sign-up/therapist',
  '/sign-up/therapist?:query',

  '/sign-up/client-askona',

  '/',

  '/business/catalog',
  '/business/faq',
  '/business/client/consultations',
  '/business/client/home',
  '/business/client/history',
  '/business/client/payments',
  '/business/client/profile',
  '/business/client/therapists',
  '/business/client/interview',
  '/business/client/onboarding',
  '/business/therapist/:url',
  '/business/therapist/clients/:id',


  '/client',
  '/client/onboarding',
  '/client/interview',
  '/client/consultations',
  '/client/history',
  '/client/therapists',
  '/client/profile',
  '/client/payments',
  '/client/faq',

  '/therapist',
  '/therapist/schedule',
  '/therapist/questionnaire',
  '/therapist/onboarding',
  '/therapist/:url',
  '/therapist/clients',
  '/therapist/clients/:id',
  '/therapist/profile',
  '/therapist/consultations',
  '/therapist/history',
  '/therapist/payments',
  '/therapist/settings',
  '/therapist/faq',

  '/all-therapists',
  '/child-therapists',
  '/privacy-policy',
  '/term-of-use',
  '/session-payment',
  '/call?:query',
  '/delete-account',
  '/check-email',
  '/confirm-email',

  '/password-recovery',
  '/password-recovery?:query',

  '/for-business',
  '/for-business/settings',
  '/test-call',
] as const;

export const ROUTES = PATHS.reduce(function (result, item) {
  result[item] = item;
  return result;
}, {} as Record<RoutesPath, string>);

export type ExtractRouteParams<T> = string extends T
  ? Record<string, string>
  : T extends `${infer _Start}:${infer Param}/${infer Rest}`
  ? { [k in Param | keyof ExtractRouteParams<Rest>]: string }
  : T extends `${infer _Start}:${infer Param}`
  ? { [k in Param]: string }
  // biome-ignore lint/complexity/noBannedTypes: <explanation>
  : {};

export type RoutesPath = typeof PATHS[number];

export type PathParams<P extends RoutesPath> = ExtractRouteParams<P>;

export const buildUrl = <P extends RoutesPath>(
  path: P,
  params: PathParams<P>,
): string => {
  let ret: string = path;

  // Upcast `params` to be used in string replacement.
  const paramObj: { [i: string]: any } = params;

  for (const key of Object.keys(paramObj)) {
    if (key === 'query') {
      ret = ret.replace(`:${key}`, queryString.stringify(paramObj[key]));
    } else {
      ret = ret.replace(`:${key}`, paramObj[key]);
    }
  }

  return ret;
};

const REDIRECT_PATH_MAP_B2B_PATIENT = {
  [ROUTES['/all-therapists']]: ROUTES['/business/catalog'],
  [ROUTES['/child-therapists']]: ROUTES['/business/catalog'],
  [ROUTES['/client/consultations']]: ROUTES['/business/client/consultations'],
  [ROUTES['/client/faq']]: ROUTES['/business/faq'],
  [ROUTES['/client/history']]: ROUTES['/business/client/history'],
  [ROUTES['/client/interview']]: ROUTES['/business/client/interview'],
  [ROUTES['/client/onboarding']]: ROUTES['/business/client/onboarding'],
  [ROUTES['/client/payments']]: ROUTES['/business/client/payments'],
  [ROUTES['/client/profile']]: ROUTES['/business/client/profile'],
  [ROUTES['/client/therapists']]: ROUTES['/business/client/therapists'],
};
  

const REDIRECT_PATH_MAP_B2C_PATIENT = {
  [ROUTES['/business/catalog']]: ROUTES['/all-therapists'],
  [ROUTES['/child-therapists']]: ROUTES['/all-therapists'],
  [ROUTES['/business/client/consultations']]: ROUTES['/client/consultations'],
  [ROUTES['/business/faq']]: ROUTES['/client/faq'],
  [ROUTES['/business/client/history']]: ROUTES['/client/history'],
  [ROUTES['/business/client/interview']]: ROUTES['/client/interview'],
  [ROUTES['/business/client/onboarding']]: ROUTES['/client/onboarding'],
  [ROUTES['/business/client/payments']]: ROUTES['/client/payments'],
  [ROUTES['/business/client/profile']]: ROUTES['/client/profile'],
  [ROUTES['/business/client/therapists']]: ROUTES['/client/therapists'],
};

export const getClientRedirectPathMap = (metadata: UserMetadataResponseDto) => {
  if (metadata?.isB2B) {
    return REDIRECT_PATH_MAP_B2B_PATIENT;
  }

  return REDIRECT_PATH_MAP_B2C_PATIENT;
}
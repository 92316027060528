import { URL_API_PUBLIC } from '@/shared/urls';
import { HttpMethods } from '@app/types';
import {
  CreateRecommendedFilterRequestDto,
  TherapistPaginationSearchResponseDto,
  TherapistSearchRequestDto,
} from '@app/api';
import { baseApi } from '../baseApi';

interface GetTherapistsWithFilterParams extends TherapistSearchRequestDto {
  isRecommendedTab: boolean;
  isRecommendedTabUnauthorized: boolean;
  interview: CreateRecommendedFilterRequestDto | undefined;
}

export const allTherapistsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTherapists: builder.query<
      TherapistPaginationSearchResponseDto,
      GetTherapistsWithFilterParams
    >({
      query: ({
        isRecommendedTab,
        isRecommendedTabUnauthorized,
        interview,
        ...params
      }) => {
        return {
          url: isRecommendedTabUnauthorized
            ? URL_API_PUBLIC.getTherapistsWithFilter
            : isRecommendedTab
            ? URL_API_PUBLIC.getTherapistsRecommended
            : URL_API_PUBLIC.getTherapistsWithFilter,
          method: HttpMethods.get,
          params: isRecommendedTabUnauthorized
            ? {
                limit: params.limit,
                page: params.page,
                order: params.order,
                orderTerms: params.orderTerms,
                gender: params.gender ? params.gender : interview?.gender,
                therapyMethods: params.therapyMethods,
                diseases: params.diseases
                  ? params.diseases
                  : interview?.diseases,
                dayLimit: ( params.dayLimit && ( +params.dayLimit >= 1 && +params.dayLimit <= 31 )) ? params.dayLimit : undefined,
                workWithLgbt: params.workWithLgbt
                  ? params.workWithLgbt
                  : interview?.workWithLgbt,
                workWithEthnicGroups: params.workWithEthnicGroups
                  ? params.workWithEthnicGroups
                  : interview?.workWithEthnicGroups,
                workingExperience: params.workingExperience,
                isApproved: params.isApproved,
                firstname: params.firstname,
                lastname: params.lastname,
                priceMin: params.priceMin,
                priceMax: params.priceMax,
                religions: params.religions
                  ? params.religions
                  : interview?.religion,
              }
            : {
                ...params,
                dayLimit: ( params.dayLimit && ( +params.dayLimit >= 1 && +params.dayLimit <= 31 )) ? params.dayLimit : undefined,
              },
        };
      },
      providesTags: ['allTherapists'],
    }),
  }),
});

export const { useLazyGetAllTherapistsQuery } = allTherapistsApi;

import { URL_API_PUBLIC } from '@/shared/urls';
import { HttpMethods } from '@app/types';
import { PromocodeResponseDto } from '@app/api';
import { baseApi } from '../baseApi';

interface CheckPromocodeParams {
  code: string;
}

interface CheckPromocodeByTherapistParams {
  code: string;
  userId: string;
}

export const promocodesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    checkPromocode: builder.mutation<
      PromocodeResponseDto,
      CheckPromocodeParams
    >({
      query: ({ code }) => ({
        url: URL_API_PUBLIC.checkPromocode,
        method: HttpMethods.post,
        params: { code },
      }),
    }),
  }),
});

export const promocodesByTherapistApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    checkPromocodeByTherapist: builder.mutation<
      PromocodeResponseDto,
      CheckPromocodeByTherapistParams
    >({
      query: (body) => ({
        url: URL_API_PUBLIC.checkPromocodeByTherapist,
        method: HttpMethods.post,
        body,
      }),
    }),
  }),
});

export const { useCheckPromocodeMutation } = promocodesApi;
export const { useCheckPromocodeByTherapistMutation } = promocodesByTherapistApi;

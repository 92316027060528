import { URL_API_PUBLIC } from '@/shared/urls';
import { HttpMethods, PaginationParams } from '@app/types';
import {
  AddSessionOutcomeRequestDto,
  AvailabilitiesResponseDto,
  CancelSessionRequestDto,
  MessageSuccessResponseDto,
  PaginatedSessionResponseDto,
  RequestSessionRequestDto,
  SessionNoteResponseDto,
  SessionNotesResponseDto,
  SessionResponseDtoStatusEnum,
  SessionsWithStartDate,
  RescheduleSessionRequestDto,
  UpdateSessionNoteRequestDto,
} from '@app/api';
import { baseApi } from '../baseApi';

interface GetPatientSessionsParams extends PaginationParams {
  from?: string;
  to?: string;
  therapistFirstname?: string;
  therapistLastname?: string;
}

type GetPatientTotalSessionsParams = Omit<
  GetPatientSessionsParams,
  'therapistFirstname' | 'therapistLastname'
> & {
  statuses?: SessionResponseDtoStatusEnum[];
};

interface GetTherapistSessionsParams extends PaginationParams {
  from?: string;
  to?: string;
  patientName?: string;
}

interface GetTherapistFreeAvailabilitiesParams {
  therapistId: string;
  from?: string;
  to?: string;
}

interface GetTinySessionsParams
  extends Pick<GetPatientSessionsParams, 'from' | 'to'> {
  isActive: boolean;
}

export const sessionsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getClientActiveSessions: builder.query<
      PaginatedSessionResponseDto,
      GetPatientSessionsParams
    >({
      query: ({ limit = 5, ...payload }) => ({
        url: URL_API_PUBLIC.getClientActiveSessions,
        method: HttpMethods.get,
        params: { ...payload, limit },
      }),
      providesTags: ['clientActiveSessions'],
    }),
    getClientHistorySessions: builder.query<
      PaginatedSessionResponseDto,
      GetPatientSessionsParams
    >({
      query: ({ limit = 5, ...payload }) => ({
        url: URL_API_PUBLIC.getClientHistorySessions,
        method: HttpMethods.get,
        params: { ...payload, limit },
      }),
      providesTags: ['clientHistorySessions'],
    }),

    getClientTotalSessions: builder.query<
      PaginatedSessionResponseDto,
      GetPatientTotalSessionsParams
    >({
      query: ({ limit = 5, ...payload }) => ({
        url: URL_API_PUBLIC.getClientTotalSessions,
        method: HttpMethods.get,
        params: { ...payload, limit },
      }),
      providesTags: ['clientTotalSessions'],
    }),

    getClientSessions: builder.query<
      SessionsWithStartDate,
      GetTinySessionsParams
    >({
      query: ({ isActive = true, ...payload }) => ({
        url: URL_API_PUBLIC.getClientSessions,
        method: HttpMethods.get,
        params: { ...payload, isActive },
      }),
      providesTags: ['clientSessionsPreviews'],
    }),

    getSessionNotes: builder.query<SessionNotesResponseDto, string>({
      query: (sessionId: string) => ({
        url: URL_API_PUBLIC.getSessionNotes(sessionId),
        method: HttpMethods.get,
      }),
      providesTags: ['sessionNotes'],
    }),

    cancelSession: builder.mutation<
      MessageSuccessResponseDto,
      CancelSessionRequestDto & { sessionId: string, cancelReason: string }
    >({
      query: (payload) => ({
        url: URL_API_PUBLIC.cancelSession(payload.sessionId),
        method: HttpMethods.patch,
        body: { cancelReason: payload.cancelReason },
      }),
      invalidatesTags: [
        'clientActiveSessions',
        'therapistActiveSessions',
        'clientHistorySessions',
        'therapistHistorySessions',
      ],
    }),

    cancelSessionV2: builder.mutation<
      MessageSuccessResponseDto,
      CancelSessionRequestDto & { sessionId: string, cancelReason: string }
    >({
      query: (payload) => ({
        url: URL_API_PUBLIC.cancelSessionV2,
        method: HttpMethods.post,
        body: { sessionId: payload.sessionId, cancelReason: payload.cancelReason },
      }),
      invalidatesTags: [
        'clientActiveSessions',
        'therapistActiveSessions',
        'clientHistorySessions',
        'therapistHistorySessions',
      ],
    }),

    timeshiftSession: builder.mutation<
      MessageSuccessResponseDto,
      RescheduleSessionRequestDto & { sessionId: string }
    >({
      query: (payload) => ({
        url: URL_API_PUBLIC.timeshiftSession,
        method: HttpMethods.post,
        body: payload,
      }),
      invalidatesTags: ['clientActiveSessions', 'therapistActiveSessions'],
    }),

    addSessionNote: builder.mutation<
      SessionNoteResponseDto,
      UpdateSessionNoteRequestDto & { sessionId: string }
    >({
      query: ({ sessionId, comment }) => ({
        url: URL_API_PUBLIC.addSessionNote(sessionId),
        method: HttpMethods.post,
        body: { comment },
      }),
      onQueryStarted: ({ sessionId }, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((res) => {
          dispatch(
            sessionsApi.util.updateQueryData(
              'getSessionNotes',
              sessionId,
              (draft) => ({ notes: [...draft.notes, res.data] }),
            ),
          );
        });
      },
    }),

    addSessionOutcome: builder.mutation<
      MessageSuccessResponseDto,
      AddSessionOutcomeRequestDto & { sessionId: string }
    >({
      query: ({ sessionId, comment, liked }) => ({
        url: URL_API_PUBLIC.addSessionOutcome(sessionId),
        method: HttpMethods.post,
        body: { comment, liked },
      }),
    }),

    getTherapistActiveSessions: builder.query<
      PaginatedSessionResponseDto,
      GetTherapistSessionsParams
    >({
      query: ({ limit = 5, ...payload }) => ({
        url: URL_API_PUBLIC.getTherapistActiveSessions,
        method: HttpMethods.get,
        params: {
          ...payload,
          limit,
        },
      }),
      providesTags: ['therapistActiveSessions'],
    }),
    getTherapistHistorySessions: builder.query<
      PaginatedSessionResponseDto,
      GetTherapistSessionsParams
    >({
      query: ({ limit = 5, ...payload }) => ({
        url: URL_API_PUBLIC.getTherapistHistorySessions,
        method: HttpMethods.get,
        params: {
          ...payload,
          limit,
        },
      }),
      providesTags: ['therapistHistorySessions'],
    }),
    getTherapistSessions: builder.query<
      SessionsWithStartDate,
      GetTinySessionsParams
    >({
      query: ({ isActive = true, ...payload }) => ({
        url: URL_API_PUBLIC.getTherapistSessions,
        method: HttpMethods.get,
        params: { ...payload, isActive },
      }),
      providesTags: ['therapistSessionsPreviews'],
    }),
    getTherapistFreeAvailabilities: builder.query<
      AvailabilitiesResponseDto,
      GetTherapistFreeAvailabilitiesParams
    >({
      query: (payload) => ({
        url: URL_API_PUBLIC.getTherapistFreeAvailabilities(payload.therapistId),
        method: HttpMethods.get,
        payload,
      }),
      providesTags: ['therapistAvailabilities'],
    }),
    sendRequestSession: builder.mutation<
      MessageSuccessResponseDto,
      RequestSessionRequestDto & {
        therapistId: string;
        patientUserId?: string;
        utmSource?: string;
        utmMedium?: string;
        utmCampaign?: string;
      }
    >({
      query: (payload) => ({
        url: URL_API_PUBLIC.sendRequestSession(payload.therapistId),
        method: HttpMethods.post,
        body: {
          phone: payload.phone,
          name: payload.name,
          patientUserId: payload?.patientUserId,
          utmSource: payload?.utmSource,
          utmMedium: payload?.utmMedium,
          utmCampaign: payload?.utmCampaign,
        },
      }),
    }),
  }),
});

export const {
  useTimeshiftSessionMutation,
  useCancelSessionMutation,
  useAddSessionNoteMutation,
  useGetClientSessionsQuery,
  useGetClientActiveSessionsQuery,
  useGetClientHistorySessionsQuery,
  useGetSessionNotesQuery,
  useGetTherapistSessionsQuery,
  useGetTherapistActiveSessionsQuery,
  useGetTherapistHistorySessionsQuery,
  useGetTherapistFreeAvailabilitiesQuery,
  useAddSessionOutcomeMutation,
  useSendRequestSessionMutation,
  useLazyGetClientTotalSessionsQuery,
  useCancelSessionV2Mutation,
} = sessionsApi;

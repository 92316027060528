import { URL_API_PUBLIC } from '@/shared/urls';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { RootState } from '..';
import queryString from 'query-string';
import { http } from '@app/services/http';
import { getTokenFromCookies } from '@app/utils/getTokenFromCookies';

export const baseQuery = fetchBaseQuery({
  baseUrl: URL_API_PUBLIC.baseUrl,
  paramsSerializer: (params) => queryString.stringify(params),
  prepareHeaders: (headers, { getState }) => {
    const substitutedToken = headers.get('authorization');

    if (substitutedToken) return headers;

    const tokenFromCookies = getTokenFromCookies();

    if (tokenFromCookies) {
      headers.set('authorization', `Bearer ${tokenFromCookies}`);
      http.setAuthorizationHeader(tokenFromCookies);
      return headers;
    }

    const { accessToken: tokenFromState } = (getState() as RootState).auth;

    if (tokenFromState) {
      headers.set('authorization', `Bearer ${tokenFromState}`);
      http.setAuthorizationHeader(tokenFromState);
      return headers;
    }

    return headers;
  },
});

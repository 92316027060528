import { CreateRecommendedFilterRequestDto } from '@app/api';
import { URL_API_PUBLIC } from '@/shared/urls';
import { HttpMethods } from '@app/types';
import { baseApi } from '../baseApi';

export const clientOnboardingApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createOnboarding: builder.mutation<any, any>({
      query: (body) => ({
        url: URL_API_PUBLIC.postClientOnboarding,
        method: HttpMethods.post,
        body,
      }),
      invalidatesTags: ['users'],
    }),
    createRecommendationFilter: builder.mutation<
      void,
      CreateRecommendedFilterRequestDto
    >({
      query: (body) => ({
        url: URL_API_PUBLIC.postClientRecommendationFilter,
        method: HttpMethods.post,
        body,
      }),
      invalidatesTags: ['allTherapists'],
    }),
  }),
});

export const {
  useCreateOnboardingMutation,
  useCreateRecommendationFilterMutation,
} = clientOnboardingApi;

import { URL_API_PUBLIC } from '@/shared/urls';
import { HttpMethods } from '@app/types';
import {
  GetPromocodesResponseDto,
  GetStatisticsResponseDto,
  MessageSuccessResponseDto,
  PromocodeRequestRequestDto,
  SendPromocodesRequestDto,
  TherapistSearchRequestDtoOrderEnum,
} from '@app/api';
import { baseApi } from '../baseApi';
import { ResponseHandler } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';

interface GetPromoCodesParams {
  isExpired: boolean;
  amountFilter?: string[];
  statusFilter?: 'expired' | 'used';
  sortTerm?: 'expireDate' | 'amount';
  sortOrder?: TherapistSearchRequestDtoOrderEnum;
}

export const companyApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyStatistic: builder.query<GetStatisticsResponseDto, number>({
      query: (year) => ({
        url: URL_API_PUBLIC.getCompanyStatistic,
        method: HttpMethods.get,
        params: { year },
      }),
    }),
    getPromoCodes: builder.query<GetPromocodesResponseDto, GetPromoCodesParams>(
      {
        query: (params) => ({
          url: URL_API_PUBLIC.getPromoCodes,
          method: HttpMethods.get,
          params,
        }),
      },
    ),
    requestPromoCodes: builder.mutation<
      MessageSuccessResponseDto,
      PromocodeRequestRequestDto
    >({
      query: (body) => ({
        url: URL_API_PUBLIC.requestPromoCodes,
        method: HttpMethods.post,
        body,
      }),
    }),
    sendPromoCodes: builder.mutation<
      MessageSuccessResponseDto,
      SendPromocodesRequestDto
    >({
      query: (body) => ({
        url: URL_API_PUBLIC.sendPromoCodes,
        method: HttpMethods.post,
        body,
      }),
    }),
    getCompanyStatisticsXls: builder.mutation<
      Blob,
      { year: string; responseHandler?: ResponseHandler }
    >({
      query: ({ responseHandler, ...params }) => ({
        url: URL_API_PUBLIC.getCompanyStatisticsXls,
        method: HttpMethods.get,
        responseHandler,
        params,
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useGetCompanyStatisticQuery,
  useGetPromoCodesQuery,
  useRequestPromoCodesMutation,
  useSendPromoCodesMutation,
  useGetCompanyStatisticsXlsMutation,
} = companyApi;

import { URL_API_PUBLIC } from '@/shared/urls';
import {
  MessageSuccessResponseDto,
  TherapistPatientNoteRequestDto,
  TherapistPatientNoteResponseDto,
} from '@app/api';
import { HttpMethods } from '@app/types';
import { baseApi } from '../baseApi';

interface PatientNotesAction {
  patientId: string;
}

interface AddPatientNoteArgs extends PatientNotesAction {
  payload: TherapistPatientNoteRequestDto;
}

interface EditPatientNoteArgs extends PatientNotesAction {
  noteId: string;
  payload: TherapistPatientNoteRequestDto;
}

interface DeletePatientNoteArgs extends PatientNotesAction {
  noteId: string;
}

export const patientNotesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPatientNotes: builder.query<
      TherapistPatientNoteResponseDto[],
      PatientNotesAction
    >({
      query: ({ patientId }) => ({
        url: URL_API_PUBLIC.addPatientNote(patientId),
        method: HttpMethods.get,
      }),
      providesTags: ['patientNotes'],
    }),
    addPatientNote: builder.mutation<
      TherapistPatientNoteResponseDto,
      AddPatientNoteArgs
    >({
      query: ({ patientId, payload }) => ({
        url: URL_API_PUBLIC.addPatientNote(patientId),
        method: HttpMethods.post,
        body: payload,
      }),
      onQueryStarted: async ({ patientId }, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(({ data }) => {
          dispatch(
            patientNotesApi.util.updateQueryData(
              'getPatientNotes',
              { patientId },
              (draft) => [data, ...draft],
            ),
          );
        });
      },
    }),
    editPatientNote: builder.mutation<
      TherapistPatientNoteResponseDto,
      EditPatientNoteArgs
    >({
      query: ({ patientId, noteId, payload }) => ({
        url: URL_API_PUBLIC.editPatientNote(patientId, noteId),
        method: HttpMethods.patch,
        body: payload,
      }),
      onQueryStarted: async (
        { patientId, noteId, payload },
        { dispatch, queryFulfilled },
      ) => {
        const patchResult = dispatch(
          patientNotesApi.util.updateQueryData(
            'getPatientNotes',
            { patientId },
            (draft) =>
              draft.map((item) => {
                if (item.id === noteId) {
                  return { ...item, comment: payload.comment };
                }

                return item;
              }),
          ),
        );

        queryFulfilled.catch(patchResult.undo);
      },
    }),
    deletePatientNote: builder.mutation<
      MessageSuccessResponseDto,
      DeletePatientNoteArgs
    >({
      query: ({ patientId, noteId }) => ({
        url: URL_API_PUBLIC.deletePatientNote(patientId, noteId),
        method: HttpMethods.delete,
      }),
      onQueryStarted: async (
        { noteId, patientId },
        { dispatch, queryFulfilled },
      ) => {
        const deleteResult = dispatch(
          patientNotesApi.util.updateQueryData(
            'getPatientNotes',
            { patientId },
            (draft) => draft.filter((item) => item.id !== noteId),
          ),
        );

        queryFulfilled.catch(deleteResult.undo);
      },
    }),
  }),
});

export const {
  useAddPatientNoteMutation,
  useGetPatientNotesQuery,
  useEditPatientNoteMutation,
  useDeletePatientNoteMutation,
} = patientNotesApi;

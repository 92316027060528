import { URL_API_PUBLIC } from '@/shared/urls';
import { HttpMethods } from '@app/types';
import {
  PatientProfileResponseDto,
  UpdatePatientProfileRequestDto,
  UpdateAvatarRequestDto,
} from '@app/api';
import { actions, RootState } from '@app/store';
import { baseApi } from '../baseApi';

export const clientProfileApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPatientProfile: builder.query<PatientProfileResponseDto, void>({
      query: () => ({
        url: URL_API_PUBLIC.getPatientProfile,
        method: HttpMethods.get,
      }),
      providesTags: ['userData'],
    }),

    updatePatientInformation: builder.mutation({
      query: (body: UpdatePatientProfileRequestDto) => ({
        url: URL_API_PUBLIC.updatePatientInformation,
        method: HttpMethods.patch,
        body,
      }),
      invalidatesTags: ['userData'],
    }),

    updateAvatar: builder.mutation({
      query: (body: UpdateAvatarRequestDto) => ({
        url: URL_API_PUBLIC.updateAvatar,
        method: HttpMethods.patch,
        body,
      }),
      onQueryStarted: (
        { avatarKey },
        { dispatch, queryFulfilled, getState },
      ) => {
        queryFulfilled.then(() => {
          const user = (getState() as RootState).auth.user;
          dispatch(actions.auth.setUser({ ...user, avatarKey }));
        });
      },
      invalidatesTags: ['userData'],
    }),

    deleteClientAvatar: builder.mutation<void, void>({
      query: () => ({
        url: URL_API_PUBLIC.deleteClientAvatar,
        method: HttpMethods.delete,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled, getState }) => {
        queryFulfilled.then(() => {
          const user = (getState() as RootState).auth.user;
          dispatch(actions.auth.setUser({ ...user, avatarKey: undefined }));
        });
      },
      invalidatesTags: ['userData'],
    }),

    deleteProfile: builder.mutation<void, void>({
      query: () => ({
        url: URL_API_PUBLIC.deleteProfile,
        method: HttpMethods.delete,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => dispatch(actions.auth.logout()));
      },
    }),
  }),
});

export const {
  useGetPatientProfileQuery,
  useDeleteProfileMutation,
  useUpdatePatientInformationMutation,
  useDeleteClientAvatarMutation,
  useUpdateAvatarMutation,
} = clientProfileApi;
